@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap");

* {
  font-family: "Inter", sans-serif;
  margin: 0;
}

/* sidebar*/
body
  > div#root
  > div
  > div[class*="sc-"]:first-child:not(.MuiCollapse-root):not(.MuiGrid-root) {
  background-color: #3e3e3e;
  box-shadow: 0px 5px 20px rgba(69, 72, 84, 0.04);
  border-color: rgba(121, 122, 124, 0);
}

/*todos os botões*/
body > div#root > div > div > ul.MuiList-root a div.MuiButtonBase-root {
  margin: 25px 0 0 0;
  background-color: #ffffff;
}

body
  > div#root
  > div
  > div
  > ul.MuiList-root
  a
  div.MuiButtonBase-root
  div.MuiListItemIcon-root
  svg {
  background-color: #ffffff;
}

body
  > div#root
  > div
  > div
  > ul.MuiList-root
  a.active-link
  div.MuiButtonBase-root {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/*container de botoes do sidebar*/
body > div#root > div > div > ul.MuiList-root {
  flex: 4;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*primeiro botao = logo*/
body
  div#root
  div
  div
  ul.MuiList-root
  a:first-child
  div:first-child
  div.MuiListItemIcon-root:first-child,
body
  div#root
  div
  div
  ul.MuiList-root
  a:first-child
  div.MuiButtonBase-root:first-child,
body > div#root > div > div > ul.MuiList-root > a:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  width: auto;
  position: fixed;
  padding-left: 1px;
  top: 0;
  margin-top: 10px;
}
